/*
DO NOT MAKE DIRECT EDITS HERE UNTIL APPROVED BY
CODE_REVIEW IN PUMPKIN_CLOUDFLARE_INFRA REPO
*/

async function fetchBreedCode(species, breed) {
    if (!['dogs', 'cats'].includes(species.toLowerCase())) {
        throw new Error(`Invalid species "${species}". Must be "dogs" or "cats".`);
    }

    const url = `https://utils.api.pumpkin.care/v1/breeds/${species.toLowerCase()}`;

    try {
        const response = await fetch(url);

        if (response.status === 200) {
            const breeds = await response.json();

            for (const breedData of breeds) {
                if (breedData.name.toLowerCase() === breed.toLowerCase()) {
                    return breedData.code;
                }
            }
            throw new Error(`Breed "${breed}" not found in the API for species "${species}".`);
        } else {
            throw new Error(`Error fetching breed data: ${response.status}`);
        }
    } catch (error) {
        console.error('Error breed', error);
    }
}

async function fetchZipCode(zipcode) {
    const url = `https://utils.api.pumpkin.care/v1/zipcodes/${zipcode}`;

    try {
        const response = await fetch(url);

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Error fetching zip data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Zipcode error', error);
        return null;
    }
}

const flowSteps = {
    "start": 1,
    "dog-breed": 2,
    "dog-interstitial-1": 4,
    "dog-insurance-journey-question": 3,
    "dog-experience-question": 5,
    "dog-worry-question": 6,
    "dog-interstitial-2": 7,
    "dog-lifestyle-question": 8,
    "dog-interstitial-3": 9,
    "cat-breed": 2,
    "cat-interstitial-1": 4,
    "cat-insurance-journey-question": 3,
    "cat-experience-question": 5,
    "cat-worry-question": 6,
    "cat-interstitial-2": 7,
    "cat-lifestyle-question": 8,
    "cat-interstitial-3": 9,
    "must-cover-question": 10,
    "interstitial-4": 11,
    "benefit-question": 12,
    "name-and-email": 13,
    "loading": 14,
    "fetch": 15,
    "name-and-email-short": 13,
    "loading-short": 14,
    "fetch-short": 15
    
};
window.flowStep = 1;
window.maxSteps = Math.max(...Object.values(flowSteps));

function setProgress(step) {
    if (flowSteps.hasOwnProperty(step)) {
        const stepValue = flowSteps[step];
        window.flowStep = Math.max(parseFloat(window.flowStep), stepValue);
        //console.log(window.flowStep);
    } else {
        console.error(`Step '${step}' is not defined in flowSteps.`);
    }
}

function getData(species) {
    const quote_pet = {
        "breed_code": window.breedCode,
        "pet_age": window.age,
        "pet_gender": window.gender,
        "pet_name": window.pet_name
    };
    
    const storedValue = localStorage.getItem('ajs_anonymous_id');
    let anonymous_id = null;
    
    if (storedValue) {
        anonymous_id = storedValue.trim();
        anonymous_id = anonymous_id.replace(/^\"+|\"+$/g, '');
        anonymous_id = anonymous_id.replace(/^\\\"+|\\\"+$/g, '');
    }
    
    //console.log(`Stored Value: ${storedValue}`);
    //console.log(`Anonymous ID: ${anonymous_id}`);
    if (species) quote_pet.species = species;
    
    return {
        "first_name": window.fname,
        "last_name": window.lname,
        "email": window.email,
        "tracking_id": anonymous_id,
        "policy_zipcode": window.zipcode,
        "retarget": true,
        "quote_pets": [quote_pet]
    };
}


window.addEventListener('heyflow-change', async (event) => {
    //console.log(event.detail);
    const {
        fieldsSimple
    } = event.detail;
    
    window.pet_name = fieldsSimple['pet_name'];
    window.species = fieldsSimple['species'];
    window.breed = fieldsSimple['breed'];
    window.age = fieldsSimple['age'];
    window.gender = fieldsSimple['gender'];
    window.fname = fieldsSimple['fname'];
    window.lname = fieldsSimple['lname'];
    window.email = fieldsSimple['email'];
    
    if ('zipcode' in fieldsSimple) {
        try {
            window.zipStatus = await fetchZipCode(fieldsSimple['zipcode']);
            if (!window.zipStatus) {
                throw new Error('Zipcode fetch returned null or an error');
            }
            // Update the window's zipcode only if fetch was successful
            window.zipcode = fieldsSimple['zipcode'];
        } catch (error) {
            console.error('Zipcode error', error);
            alert("Please enter a valid US 5-digit zipcode");
            // Do not update the window's zipcode if there was an error
        }
    }
});

window.addEventListener('heyflow-screen-view', async(event) => {
    setProgress(event.detail.screenName);
    //console.log(event.detail.screenName);
    /* 
    this should be before any blocking from async calls
    don't do this on start to prevent double analytics, it is
    already invoked in GTM
    check that window analytics has loaded for user before
    trying to do analytics
    */ 
    if (
        event.detail.screenName !== 'start' &&
        window.analytics &&
        window.analytics.page
    ) {
        const data = getData(window.species);
        window.analytics.page(
            'heyflow-quote-flow-variant2',
            event.detail.screenName,
            {
                ...data,
                "pages_seen": window.flowStep,
                "pages_in_flow": window.maxSteps
            }
        )
    }
    
    

    if (event.detail.screenName === 'loading'|| event.detail.screenName === 'loading-short') {
        window.apiResponse = null; 
        window.breedCode = await fetchBreedCode(window.species, window.breed);
        await new Promise(resolve => setTimeout(resolve, 500));
        const data = {
            ...getData()
        };
        //console.log(data);
        // Send a post request

        const response = await fetch('https://policy.api.pumpkin.care/v2/partner-quotes/internal-plan-recommendation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        
        //console.log(response);
        // Check if the request was successful
        if (response.ok) {
            // Save response to be used in 'heyflow-submit' event.
            window.apiResponse = await response.json();
            //console.log(window.apiResponse);
        } else {
            console.error(`API request failed with status ${response.status}`);
        }

    }
});

window.addEventListener('heyflow-submit', async (event) => {
    let counter = 0;
    const timeout = setInterval(() => {
        counter += 1;
        if (window.apiResponse) {
            const quoteId = window.apiResponse.body.id;
            const newUrl = `https://get.pumpkin.care/?quoteId=${quoteId}`;

            // Use the window.location object to redirect to the new URL
            window.location.href = newUrl;
            clearInterval(timeout);
        } else if (counter >= 45) {
            // If the apiResponse is not available after 10 seconds, redirect to another URL
            alert("Uh-oh! You’re being redirected to the start of the flow due to an error.");
            window.location.href = 'https://get.pumpkin.care/quote/intro';
            clearInterval(timeout);
        }
    },
        500);
});